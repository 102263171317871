import { useState, useEffect } from 'react';
import { ethers } from "ethers";
import AppConfigService from '../services/AppConfigService';
import avaxLogo from "../assets/avalanche-avax-logo.png";

const AccountBar = ({ ethAccount }) => {
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const getBalance = async () => {
      if (ethAccount) {
        const provider = await AppConfigService.getWeb3NetworkProvider();
        const balance = await provider.getBalance(ethAccount);
        setBalance(ethers.utils.formatEther(balance));
      }
    };
    getBalance();
  }, [ethAccount]);

  return (
    <div>
      <div className="flex justify-items-center items-center border drop-shadow-md rounded-full px-6 py-2 hover:bg-gray-100 hover:cursor-pointer">
        <div>
          {Math.round(balance * 10000) / 10000} AVAX
        </div>
        <div className="px-2">
          <img
            className="inline-block h-6 w-6 rounded-full"
            src={avaxLogo}
            alt="Avalanche AVAX Logo"
          />
        </div>
        <div>
          {ethAccount.substring(0, 6)}...{ethAccount.substring(ethAccount.length - 4)}
        </div>
      </div>
    </div>
  )
}

export default AccountBar;