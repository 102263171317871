const Header = () => {
  return (
    <div className="grid grid-cols-1 justify-items-center pt-16">
      <h2 className='text-base text-gray-600 font-semibold tracking-wide uppercase'>
        Close As You Go
      </h2>
      <h1 className='mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
        Challenge Coin NFTs
      </h1>
      <span className="mt-4 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800">
        v0.1.1 Alpha
      </span>
    </div>
  )
}

export default Header;