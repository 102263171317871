import { CheckCircleIcon } from '@heroicons/react/solid'

const SuccessAlert = () => {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">Claim submitted</h3>
          <div className="mt-2 text-sm text-green-700">
            <p>Your claim has been submitted. Your NFT is now being transferred to your account. 
              When the transfer is complete you can view your coin in the {' '} 
              <span className='font-bold'>Your Coins</span> tab.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessAlert;