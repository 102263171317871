import CoinCard from "./CoinCard";


const CoinsList = ({ coins, loading, error }) => {

  if (error) {
    console.error(error);
    return <div>Error loading coins</div>;
  }

  if (loading) {
    return (
      <p>Loading...</p>
    )
  }

  if (coins.length === 0) {
    return (
      <div className="flex flex-wrap justify-center my-6">
        <p className="text-center text-md text-gray-800">
          You don't have any coins yet. If you've received a challenge coin, go to the {' '}
          <span className='font-bold'>Claim a coin</span> tab to claim it.
        </p>
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-wrap justify-center my-6">
        {coins.map((tokenId, index) => {
          return (
            <CoinCard key={index} tokenId={tokenId} />
          )
        })}
      </div>
    </>
  );
}

export default CoinsList;