import { useState, useEffect } from 'react';
import Header from './components/Header';
import Tabs from './components/Tabs';
import AccountBar from './components/AccountBar';
import ClaimForm from './components/ClaimForm';
import CoinsList from './components/CoinsList';
import Connect from './components/Connect';
import useCoins from './hooks/useCoins';
import styles from './App.module.css';


const App = () => {
  const [ethereum, setEthereum] = useState(null);
  const [ethAccount, setEthAccount] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const { coins, loading, error } = useCoins(ethAccount);

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      setEthereum(window.ethereum);
    }
  }, []);

  if (ethereum) {
    ethereum.on('accountsChanged', (accounts) => {
      setEthAccount(accounts[0]);
    });
  }

  useEffect(() => {
    if (ethAccount) {
      if (coins.length > 0) {
        setCurrentTab(1);
      } else {
        setCurrentTab(0);
      }
    }
  }, [ethAccount, coins]);

  return (
    <>
      <div className={styles.background}>
        <div className="bg-white max-w-5xl min-h-screen mx-auto mb-auto pb-12 border-x-2">
          <Header />
          <div className='flex text-center justify-center mt-10 mb-4'>
            {ethAccount ? 
              <AccountBar ethAccount={ethAccount} />
              : <Connect ethereum={ethereum} setEthAccount={setEthAccount} />}
          </div>
          {ethAccount && (
            <div className='w-11/12 mt-12 mx-auto' >
              <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
              {currentTab === 0 && <ClaimForm ethAccount={ethAccount} />}
              {currentTab === 1 && <CoinsList coins={coins} loading={loading} error={error} />}
            </div>
          )}
        </div>
      </div>
      <footer className="bg-white border-t-2">
        <div className="max-w-5xl mx-auto py-12 px-4">
          <div className="mt-8 md:mt-0">
            <p className="text-center text-base text-gray-400">&copy; 2022. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
