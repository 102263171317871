import get from 'lodash/get';
import appConfig from '../appConfig/appConfig';
import { initializeApp } from "firebase/app";
import { doc, getDoc } from "firebase/firestore";
import { ref, getBlob } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import firebaseConfig from "../appConfig/firebaseConfig";
import { ethers } from "ethers";


const environment = process.env.NODE_ENV || 'development';


class AppConfigService {
  #app = null;
  db = null;
  storage = null;
  #config = null;

  constructor(config) {
    this.#app = initializeApp(firebaseConfig);
    this.db = getFirestore(this.#app);
    this.storage = getStorage(this.#app);
    this.#config = config;

    if (environment === 'development') {
      connectFirestoreEmulator(this.db, 'localhost', 8080);
      connectStorageEmulator(this.storage, "localhost", 9199);
    }
  }

  getContractsCollection() {
    return get(this.#config, `${environment}.collections.contracts`);
  }

  getContractAddress = () => {
    return get(this.#config, `${environment}.nftContractAddress`);
  }

  getWeb3NetworkProvider = async () => {
    try {
      const contractSnapshot = await getDoc(doc(
        this.db, 
        this.getContractsCollection(), 
        this.getContractAddress()));
      const provider = new ethers.providers.JsonRpcProvider(contractSnapshot.data().network.url);
      return provider;
    } catch (error) {
      console.error(error);
    }
  }

  getContractAbi = async () => {
    const abiFolder = get(this.#config, `${environment}.abiFolder`);
    const contractAddress = this.getContractAddress();
    const blob = await getBlob(ref(
      this.storage,
      `${abiFolder}/${contractAddress}.json`
    ));
    return await blob.text();
  }

  getContractInstance = async () => {
    const provider = await this.getWeb3NetworkProvider();
    const abi = await this.getContractAbi();
    const contract = new ethers.Contract(this.getContractAddress(), abi, provider);
    return contract;
  }
}

export default new AppConfigService(appConfig);