import { useState, useEffect, useCallback } from "react";
import AppConfigService from "../services/AppConfigService";

const useCoins = (ethAccount) => {
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getCoins = useCallback(async () => {
    if (ethAccount) {
      setLoading(true);
      try {
        const contract = await AppConfigService.getContractInstance();
        const accountBalance = await contract.balanceOf(ethAccount);
        const newCoins = [];
        for (let i = 0; i < accountBalance; i++) {
          const tokenId = await contract.tokenOfOwnerByIndex(ethAccount, i);
          newCoins.push(tokenId.toNumber());
        }
        setCoins(newCoins);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    }
  }, [ethAccount]);

  const listenForCoins = useCallback(async () => {
    const contract = await AppConfigService.getContractInstance();
    contract.on("Transfer", (from, to, tokenId) => {
      if (toString(to) === toString(ethAccount)) {
        getCoins();
      }
    });
  }, [getCoins, ethAccount]);

  /*
   * This useEffect is called when the user changes their account.
   * It will fetch the list of owned coins from the smart contract, and listen for new coins.
   * It will also update the react state with the new list of owned coins.
   */ 
  useEffect(() => {
    console.log('poop')
    getCoins();
    listenForCoins();
  }, [getCoins, listenForCoins, ethAccount]);

  return {
    coins,
    loading,
    error
  }
}

export default useCoins;
