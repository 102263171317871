const Connect = ({ ethereum, setEthAccount}) => {
  return (
    <div className="grid grid-cols-1">
      <div>
        <p>Connect your Metamask account to continue.</p>
      </div>
      <div className='mt-8'>
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          onClick={async () => {
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            setEthAccount(accounts[0]);
          }}
        >
          Connect
        </button>
      </div>
    </div>
  )
}

export default Connect;