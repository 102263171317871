const firebaseConfig = {
  apiKey: "AIzaSyBzr00W1zl5KBBUxHoP9KPFjnALfOjFzjQ",
  authDomain: "cayg-challenge-coin-nfts.firebaseapp.com",
  projectId: "cayg-challenge-coin-nfts",
  storageBucket: "cayg-challenge-coin-nfts.appspot.com",
  messagingSenderId: "102513874636",
  appId: "1:102513874636:web:d0cf78ecfc88326b815469",
  measurementId: "G-8L3SLDCR8V"
};

export default firebaseConfig;