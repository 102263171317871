import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { XCircleIcon } from '@heroicons/react/solid'
import SuccessAlert from './SuccessAlert';
import { doc, updateDoc, collection, query, where, getDocs, getDoc } from "firebase/firestore";
import AppConfigService from "../services/AppConfigService";

const ClaimForm = ({ ethAccount }) => {
  const [claiming, setClaiming] = useState(false);
  const [success, setSuccess] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [error, setError] = useState(null);

  const getCoinsSnapshot = async (data) => {
    try {
      const q = query(collection(AppConfigService.db, "coins"), 
        where("tokenId", "==", parseInt(data.tokenId)), 
        where("contract", "==", AppConfigService.getContractAddress()));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        throw new Error("No coins found");
      } else {
        return querySnapshot;
      }
    } catch (error) {
      setError(error);
    }
  }

  const processSnapshot = async (data, snapshot) => {
    snapshot.forEach( async (coin) => {
      const coinRef = doc(AppConfigService.db, "coins", coin.id);
      try {
        const coinSnap = await getDoc(coinRef);
        if (coinSnap.data().claim) {
          throw new Error("Coin already claimed");
        }
        if (coin.data().passcode === data.passcode) {
          await updateDoc(coinRef, { claim: {
            ethAccount: ethAccount,
            claimedAt: new Date().toISOString()
          }});
          setSuccess(true);
        } else {
          throw new Error("Invalid passcode");
        }
      } catch (error) {
        setError(error);
      }
    });
  }


  const onSubmit = async (data) => {
    setClaiming(true);
    const snapshot = await getCoinsSnapshot(data);
    if (snapshot) {
      processSnapshot(data, snapshot);
    }
    setClaiming(false);
  }

  return (
    <>
      {success && (
        <div className='flex justify-center mb-12'>
          <SuccessAlert />
        </div>
      )}
      <div className='flex justify-center'>
        <form 
          onSubmit={handleSubmit(onSubmit)}
          className='flex flex-col max-w-sm space-y-2'
        >
          <div className='mb-2'>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Claim your coin</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              If you've received a challenge coin, use the form below to claim its NFT.
            </p>
          </div>
          <div>
            <label htmlFor="tokenId" className="block text-sm font-medium text-gray-700">
              Token ID
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="tokenId"
                {...register('tokenId', { required: true })}
                id="tokenId"
                className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                autoComplete='off'
              />
              {errors.tokenId && (
                <span
                  className="text-red-500 text-sm mt-1"
                >
                  This field is required
                </span>
              )}
            </div>
          </div>
          <div>
            <label htmlFor="passcode" className="block text-sm font-medium text-gray-700">
              Passcode
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="passcode"
                {...register('passcode', { required: true })}
                id="passcode"
                className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                autoComplete='off'
              />
              {errors.passcode && (
                <span
                  className="text-red-500 text-sm mt-1"
                >
                  This field is required
                </span>
              )}
            </div>
          </div>
          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{error.message}</h3>
                </div>
              </div>
            </div>
          )}
          <div className='flex justify-center'>
            <button 
              type='submit'
              disabled={claiming}
              className="w-4/12 inline-flex justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"  
            >
              {claiming ? (
                <>
                  {'Claiming...'}
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default ClaimForm;