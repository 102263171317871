import { useEffect, useState } from 'react'
import ClaimForm from "./ClaimForm"
import CoinsList from "./CoinsList"

const classNames = (...classes) => classes.filter(Boolean).join(' ')

const Tabs = ({ currentTab, setCurrentTab}) => {
  const [tabs, setTabs] = useState([
    { name: 'Claim a coin', component: <ClaimForm />, current: true },
    { name: 'Your coins', component: <CoinsList />, current: false },
  ])

  useEffect(() => {
    setTabs(tabs.map(tab => ({ ...tab, current: tab.name === tabs[currentTab].name })))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]) // putting tabs in this array will cause the effect to run in an infinite loop

  const handleClick = (name) => {
    const tab = tabs.find((tab) => tab.name === name)
    setCurrentTab(tabs.indexOf(tab))
  }

  const handleSelect = (e) => {
    const tab = tabs.find((tab) => tab.name === e.target.value)
    setCurrentTab(tabs.indexOf(tab))
  }

  return (
    <div className="mb-12">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={(e) => handleSelect(e)}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                className={classNames(
                  tab.current
                    ? 'border-gray-500 text-gray-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm hover:cursor-pointer'
                )}
                aria-current={tab.current ? 'page' : undefined}
                onClick={() => handleClick(tab.name)}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Tabs;