import NFTArtPlaceholder from "../assets/NFTArtPlaceholder.png";

const CoinCard = ({ tokenId }) => {
  return (
    <div 
      className="bg-white border-2 max-w-sm m-4"
    >
      <div className="grid grid-cols-1">
        <img
          className="object-contain max-h-sm"
          src={NFTArtPlaceholder}
          alt="NFT Art Placeholder"
        />
      </div>
      <div className="p-2 flex flex-wrap text-left">
        <p className="text-sm leading-5 font-medium text-gray-900">
          Token ID: {tokenId}
        </p>
        <p className="mt-1 text-sm leading-5 text-gray-500">
          This token is a digital representation of the challenge coin you received for your contributions to the Close As You Go project.
        </p>
      </div>
    </div>
  )
}

export default CoinCard;